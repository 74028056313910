@media (max-width: 1249px) {}

@media (max-width: 1023px) {}

@media (max-width: 767px) {

      .auth-main {
            flex-direction: column;
      }

      .auth-left-main {
            display: none;
      }

      .auth-right-main {
            flex: 0 0 100%;
            max-width: 100%;
      }

      .auth-card-inner {
            max-width: 100%;
      }
      .auth-card-main{
            padding: 0px 15px;
      }

}

@media (max-width: 549px) {}

@media (max-width: 374px) {}