* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins-Regular";
  font-size: 15px;
  color: #181d1f;
  line-height: 22px;
  background-color: var(--body-color);
}

:root {
  --primary: #1c252e;
  --secondary: #687d92;
  --red: #e96767;
  --green: #5cc184;
  --light-gray: #a8b2b5;
  --body-color: #f9f9fc;
  --black: #000000;
  --white: #ffffff;
  --border: #eef2f71a;
  --dark-border: #2f2f2f1a;
  --selected-color: #0a2540;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}

@font-face {
  font-family: "Nunito-Light";
  src: url("../fonts/Nunito/NunitoSansCondensed-Light.ttf");
}

@font-face {
  font-family: "Nunito-Regular";
  src: url("../fonts/Nunito/NunitoSansCondensed-Regular.ttf");
}

@font-face {
  font-family: "Nunito-Medium";
  src: url("../fonts/Nunito/NunitoSansCondensed-Medium.ttf");
}

@font-face {
  font-family: "Nunito-SemiBold";
  src: url("../fonts/Nunito/NunitoSansCondensed-SemiBold.ttf");
}

@font-face {
  font-family: "Nunito-ExtraBold";
  src: url("../fonts/Nunito/NunitoSansCondensed-ExtraBold.ttf");
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* start new css  */

.admin-layout-main {
  display: flex;
  width: 100%;
  background-color: var(--primary);
}

.admin-layout-left-main {
  max-width: 230px;
  width: 100%;
  background-color: var(--primary);
  box-shadow: 0 5px 20px #adb5d908;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 999;
  border-right: 1px solid var(--main-border);
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
}

.sidebar-open .admin-layout-left-main {
  max-width: 70px;
}

.admin-layout-right-main {
  flex: 1;
}

.admin-layoyt-content-main {
  background-color: var(--body-color);
  min-height: 100vh;
  padding: 70px 20px 20px 245px;
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  /* height: calc(100vh - 138px);
  overflow-y: scroll; */
}

.sidebar-open .admin-layoyt-content-main {
  padding: 70px 20px 20px 85px;
}

.sidebar-open .admin-header-main {
  left: 70px;
}

.admin-header-main {
  box-shadow: 0 0 50px #00000008;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 230px;
  right: 0;
  z-index: 9;
  padding: 10px 15px 10px;
  border-bottom: 1px solid var(--border);
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -ms-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
}

.admin-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header-row .header-text {
  font-size: 15px;
  line-height: 21px;
  font-family: "Nunito-ExtraBold";
  color: var(--primary);
}

.admin-drop-header-menu .MuiPaper-rounded {
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 1px;
  margin-top: 13px;
  border-radius: 1px;
  box-shadow: 0 10px 24px 0 rgba(62, 57, 107, 0.18);
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-drop-header-menu .admin-drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary);
  font-weight: 400;
  padding: 7px 16px;
  gap: 10px;
  border-bottom: 1px solid var(--border);
}

.admin-drop-header-menu .admin-drop-header-menuitem:last-child {
  border-bottom: none;
}

.admin-drop-header-menu ul {
  padding: 0px;
  min-width: 150px;
}

.admin-header-drop-main .admin-drop-header-btn {
  padding: 0;
  min-width: 50px;
}

.admin-header-profile-img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  border-radius: 50%;
}

.profile-content-main .admin-profile-img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: contain;
  display: flex;
  margin: auto;
}

.admin-header-drop-main .admin-header-menu-name {
  font-size: 12px;
  font-family: "Poppins-Medium";
  line-height: 18px;
  color: var(--primary);
  text-align: start;
  margin-left: 7px;
  text-transform: capitalize;
}

.admin-header-drop-main .admin-header-menu-position {
  font-size: 10px;
  font-family: "Poppins-Regular";
  line-height: 16px;
  text-align: start;
  margin-left: 7px;
  color: var(--secondary);
  text-transform: capitalize;
}

.admin-page-title-main .admin-page-title {
  color: var(--primary);
  font-size: 17px;
  line-height: 24px;
  font-family: "Poppins-Semibold";
  position: relative;
  padding-left: 10px;
}

.admin-page-title-main .admin-page-title::after {
  content: "";
  position: absolute;
  height: 22px;
  width: 4px;
  background-color: var(--selected-color);
  left: 0px;
}

.admin-user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
  flex-wrap: wrap;
}

.admin-search-right-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.admin-breadcrumb-main {
  display: flex;
  gap: 5px;
  align-items: center;
}

.admin-breadcrumb-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 5px;
}

.admin-breadcrumb-main .admin-breadcrumb-text {
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 20px;
  color: var(--secondary);
  padding: 0;
  text-transform: capitalize;
  min-width: max-content;
}

.admin-breadcrumb-main .admin-breadcrumb-text.admin-breadcrumb-active {
  color: var(--primary);
  font-family: "Poppins-SemiBold";
}

.header-search-box {
  min-width: 300px;
  width: 100%;
  position: relative;
}

.header-search-box .admin-form-group .admin-form-control input {
  background-color: var(--body-color);
  border: 0;
}

.admin-form-group .admin-form-control {
  width: 100%;
}

.admin-form-group .admin-form-control-textarea {
  border: 1px solid var(--dark-border);
  border-radius: 6px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: var(--secondary);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.admin-form-group .admin-form-control input {
  border: 1px solid var(--dark-border);
  border-radius: 6px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: var(--secondary);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.admin-form-group .admin-form-control input::placeholder {
  color: var(--secondary);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.admin-form-group .admin-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.admin-form-control fieldset {
  border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--dark-border) !important;
}

.admin-form-control-textarea:focus-visible {
  outline: none !important;
}

.admin-input-box .admin-form-lable {
  font-size: 12px;
  line-height: 17px;
  font-family: "Poppins-Medium";
  margin: 0px 0px 6px;
  color: var(--black);
}

.admin-input-box .MuiInputBase-root {
  width: 100%;
}

.admin-input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.admin-form-group .admin-form-control-eye {
  border: 1px solid var(--dark-border);
}

.admin-input-box .admin-form-control-eye input {
  border: 0px;
}

.admin-input-box .admin-form-control-eye input::placeholder {
  color: var(--secondary);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.btn-main .btn {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  text-transform: capitalize;
}

.border-btn-main .border-btn {
  border: 1px solid var(--dark-border);
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

body .admin-error-text {
  color: var(--red);
  font-size: 12px;
  line-height: 15px;
  font-family: "Poppins-Regular";
  margin: 0;
}

.admin-dropdown-box .admin-form-control {
  width: 100%;
  position: relative;
}

.admin-dropdown-select .MuiSelect-select {
  color: var(--secondary);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  padding: 8.5px 15px;
  border-radius: 4px;
  border: 1px solid var(--dark-border);
}

.admin-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.admin-dropdown-box .MuiMenu-list .admin-drop-menuitem {
  color: var(--secondary);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.admin-table-data-btn-flex .admin-table-edit-btn,
.admin-table-data-btn-flex .admin-table-edit-btn:hover {
  background-color: rgb(96 74 227 / 10%);
}

.admin-table-data-btn-flex .admin-table-view-btn,
.admin-table-data-btn-flex .admin-table-view-btn:hover {
  background-color: rgb(50 58 70 / 10%);
}

.admin-table-data-btn-flex .admin-table-delete-btn,
.admin-table-data-btn-flex .admin-table-delete-btn:hover {
  background-color: rgb(233 103 103 / 10%);
}

.admin-table-data-btn-flex .admin-table-reject-btn,
.admin-table-data-btn-flex .admin-table-reject-btn:hover {
  background-color: rgb(240 147 78 / 10%);
}

.admin-table-data-btn-flex .admin-table-approve-btn,
.admin-table-data-btn-flex .admin-table-approve-btn:hover {
  background-color: rgb(92 193 132 / 10%);
}

.admin-table-data-btn-flex .admin-table-print-btn,
.admin-table-data-btn-flex .admin-table-print-btn:hover {
  background-color: rgb(96 125 139 / 20%);
}

.admin-table-data-btn-flex .admin-table-status-btn,
.admin-table-data-btn-flex .admin-table-status-btn:hover {
  background-color: rgb(92 193 132 / 10%);
}

.admin-table-data-btn-flex .admin-table-cancell-btn,
.admin-table-data-btn-flex .admin-table-cancell-btn:hover {
  background-color: rgb(240 147 78 / 10%);
}

.admin-table-data-btn-flex .admin-table-download-btn,
.admin-table-data-btn-flex .admin-table-download-btn:hover {
  background-color: rgb(69 197 205 / 10%);
}

.admin-table-data-flex {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.admin-table-data-flex .admin-table-profile-img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.admin-table-data-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.admin-table-data-btn-flex .admin-table-data-btn {
  min-width: 25px;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.admin-table-data-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 5px;
}

.table-td .admin-table-data-text {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
}

.page-table-main .table-paper {
  box-shadow: none;
}

.page-table-main .table-container {
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table {
  min-width: 1000px;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary);
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid var(--border);
  background-color: rgb(10 37 64 / 10%);
}

.page-table-main .table-td {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid #eef2f7fc;
}

.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
  background: var(--border);
  border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.common-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 15px;
}

.admin-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: var(--selected-color);
  border-radius: 6px;
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  padding: 9px 10px;
  text-transform: capitalize;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.search-input-box .admin-form-group .admin-form-control input {
  background-color: var(--white);
  padding: 10px 10px 10px 35px;
}

.search-input-box {
  position: relative;
}

.search-grey-img {
  position: absolute;
  left: 10px;
  top: 24%;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.add-btn-main .add-btn-icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  object-fit: contain;
}

.data-not-found-main {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 9px;
  width: 100%;
  margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-family: "Poppins-Medium";
  text-align: center;
}

.sidebar-logo {
  height: 30px;
  max-width: 45px;
  width: 100%;
  object-fit: contain;
}

.admin-sidebar-logo .logo-text {
  font-size: 18px;
  line-height: 26px;
  color: var(--light-gray);
  font-family: "Poppins-Medium";
  transition: 0.5s all ease-in-out;
}

.admin-sidebar-logo {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px;
  border-bottom: 1px solid var(--border);
}

.admin-nav-main .admin-sidebar-list {
  padding: 0px;
  margin-top: 5px;
}

.admin-sidebar-list .sidebar-list-item.sidebar-list-item-active {
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.admin-sidebar-list .sidebar-list-item.sidebar-list-item-active svg {
  fill: var(--white);
}

.sidebar-nav-svg svg {
  fill: var(--light-gray);
  height: 23px;
  width: 23px;
}

.sidebar-nav-svg {
  height: 30px;
  width: 30px;
  border: 1px solid var(--border);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-sidebar-list .expandless-icon,
.admin-sidebar-list .expandmore-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  fill: var(--light-gray);
  position: absolute;
  right: 15px;
}

.admin-sidebar-list .sidebar-list-item {
  padding: 7px;
}

.admin-sidebar-list .sidebar-nav-link {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.admin-sidebar-list .sidebar-nav-text {
  font-size: 14px;
  line-height: 18px;
  color: var(--light-gray);
  font-family: "Poppins-Regular";
}

.admin-layout-left-main .my-logo {
  background-color: #29323a;
  bottom: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 6%;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.admin-layout-left-main svg {
  height: 20px;
  width: 20px;
  object-fit: contain;
  fill: var(--light-gray);
}

.sidebar-open .sidebar-nav-text,
.sidebar-open .logo-text {
  display: none;
}

/* .sidebar-open .my-logo {
  right: 20%;
} */

.sidebar-open .admin-sidebar-list .sidebar-nav-link {
  justify-content: center;
}

.sidebar-open .admin-sidebar-list .expandless-icon,
.sidebar-open .admin-sidebar-list .expandmore-icon {
  display: none;
}

.sidebar-open .admin-sidebar-list .sidebar-list-item {
  align-items: center;
  justify-content: center;
}

.qty-title {
  font-size: 11px;
  line-height: 14px;
  color: var(--primary);
  font-family: "Poppins-Medium";
  margin-left: 5px;
}

.trash-gif {
  height: 60px;
  width: 60px;
  object-fit: contain;
  margin: 0px auto 10px;
  display: flex;
}

/* start common modal css */
.admin-modal .admin-modal-inner {
  padding: 30px 15px;
  width: calc(100% - 30px);
  max-height: calc(100vh - 40px);
  border: 1px solid var(--border);
  border-radius: 10px;
  background-color: var(--white);
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 194px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.admin-modal-close-icon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.admin-modal .admin-add-user-modal-inner-main {
  max-width: 500px;
}

.admin-span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.admin-modal .admin-modal-title {
  color: var(--primary);
  font-size: 16px;
  line-height: 21px;
  font-family: "Poppins-Medium";
}

.admin-modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 70%);
}

.admin-modal-footer {
  padding: 15px 0px 0px;
}

.admin-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* end common modal css */

.admin-user-circle-img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.admin-modal .admin-delete-modal-inner-main {
  max-width: 400px;
}

.admin-delete-modal-inner-main .admin-delete-modal-title {
  color: var(--primary);
  font-size: 17px;
  line-height: 31px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.admin-delete-modal-inner-main .admin-delete-modal-para {
  color: var(--secondary-color);
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 25px;
}

.admin-delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  gap: 15px;
}

.admin-delete-modal-btn-flex .admin-modal-delete-btn,
.admin-delete-modal-btn-flex .admin-modal-delete-btn:hover {
  color: var(--white);
  background-color: var(--selected-color);
  padding: 5px 20px;
  text-transform: capitalize;
  border-radius: 5px;
  font-size: 14px;
  line-height: 25px;
  font-family: "Poppins-Medium";
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  min-width: 93px;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */
.admin-btn-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 20px 0px 0px 0px;
  border-top: 1px solid var(--dark-border);
  padding: 20px 0px 0px 0px;
}

.card-title-main .card-title {
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 20px;
  color: var(--primary);
  position: relative;
  max-width: max-content;
  padding: 0px 0px 5px 0px;
}

.card-title-main {
  margin: 0px 0px 20px 0px;
}

.card-title-main .card-title::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  background-color: var(--primary);
  bottom: 0px;
  left: 0;
}

.file-upload-outer-main {
  max-width: max-content;
}

.file-upload-main {
  border: 2px dashed var(--dark-border);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 100px;
  width: 100%;
  max-width: 100px;
  position: relative;
  cursor: pointer;
}

.file-upload-inner .file-upload-control input {
  height: 100px;
  width: 100%;
  max-width: 100px;
}

.file-upload-control {
  opacity: 0;
  z-index: 1;
}

.file-upload-main .input-image-name {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: var(--primary);
  font-family: "Poppins-Regular";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-control input {
  padding: 0;
}

.file-upload-close-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--selected-color);
  border-radius: 50%;
  display: flex;
}

.file-upload-close-icon svg {
  fill: var(--white);
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.file-upload-show-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.admin-form-group .admin-autocomplete .MuiInputBase-root {
  padding: 0 !important;
  border: 1px solid var(--dark-border);
}

.admin-form-group .admin-autocomplete .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 10px 15px;
  border: 0;
}

.add-indent-table-main {
  margin: 20px 0px;
}

.add-indent-row {
  margin: 20px 0px;
}

.drawer {
  min-width: 350px;
}

.drawer-header {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}

.drawer-header .drawer-header-title {
  color: var(--white);
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 20px;
  text-transform: capitalize;
}

.drawer-header .drawer-cancel-icon {
  fill: var(--white);
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.drawer-body {
  padding: 15px;
  height: calc(100vh - 114px);
}

.drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 10px 15px;
  border-top: 1px solid var(--dark-border);
}

.drawer-footer-button-main .drawer-footer-button,
.drawer-footer-button-main .drawer-footer-button:hover {
  background-color: transparent;
  border-radius: 6px;
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  padding: 10px 20px;
  text-transform: capitalize;
  background-color: var(--primary);
  width: 100%;
}

.drawer-footer-button-main {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.drawer-footer-button-main .primary-btn-main {
  width: 100%;
}

.user-img-main {
  position: absolute;
  left: 0;
  height: 160px;
  width: 160px;
  padding: 0;
  right: 0;
  top: 0px;
  border: 1px solid var(--dark-border);
  background: var(--white);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.file-upload-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
}

.user-image-btn.user-image-btn-box.img-box label.user-submit-btn-btn {
  background: transparent;
  height: 160px;
  width: 160px;
  color: #000;
  flex-direction: column;
  border: 1px solid #384874;
  bottom: -2px;
  text-align: center;
  border-radius: 50%;
  padding: 0;
}

.user-image-btn {
  position: relative;
}

/* start file upload img css */

.admin-file-upload-btn-main .admin-file-upload-btn,
.admin-file-upload-btn-main .admin-file-upload-btn:hover {
  background-color: var(--selected-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  min-width: 30px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 0;
  box-shadow: 0px 3px 6px #00000029;
}

.admin-file-upload-btn-main {
  position: relative;
  max-width: max-content;
}

.admin-upload-profile-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.admin-upload-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

/* end file upload img css */

.user-img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.product-box {
  border: 1px solid var(--dark-border);
  border-radius: 10px;
  padding: 10px;
}

.product-box .qty-title {
  font-size: 13px;
  line-height: 20px;
  color: #181d1f;
  font-family: "Poppins-Regular";
}

.page-not-found-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.page-not-found-main .page-not-found-title {
  font-size: 30px;
  line-height: 40px;
  font-family: "Poppins-Medium";
  color: var(--primary-color);
  margin-bottom: 30px;
}

.page-not-found-main .page-not-found-para {
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Regular";
  color: var(--secondary-color);
  margin-bottom: 30px;
}

.page-not-found-img {
  height: auto;
  width: 300px;
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.page-not-found-main .page-not-found-btn,
.page-not-found-main .page-not-found-btn:hover {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  padding: 15px 40px;
  text-transform: capitalize;
  margin: 20px 0px 0px 0px;
}

.vendor-table-main .table {
  min-width: 1500px;
}

.product-table-main .table {
  min-width: 1500px;
}

.add-role-input-box {
  max-width: 400px;
  margin-bottom: 15px;
}

.table-td .MuiCheckbox-root {
  padding: 0;
}

.table-td .MuiCheckbox-root svg {
  height: 15px;
  width: 15px;
}

.change-pass-main .change-pass-para {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
  margin-bottom: 20px;
}

.page-detail-flex {
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-detail-flex .page-lable {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary);
  font-family: "Poppins-Medium";
}

.page-detail-flex .page-value {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
}

.edit-btn-main .edit-btn,
.edit-btn-main .edit-btn:hover {
  background-color: transparent;
  color: var(--selected-color);
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  padding: 7px 15px;
  text-transform: capitalize;
  gap: 10px;
  border: 1px solid var(--dark-border);
  box-shadow: none;
}

.edit-btn-main .edit-icon-svg {
  height: 16px;
  width: 18px;
  object-fit: contain;
}

.profile-view-img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.profile-flex {
  display: flex;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}

.admin-radio-main .radio {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: row;
}

.admin-radio-main .MuiFormControlLabel-label {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
  margin-right: 0;
  font-family: "Poppins-Regular";
}

.admin-radio-main .MuiRadio-root {
  padding: 0;
}

.admin-radio-main .radio-label {
  margin: 0;
  gap: 15px;
}

.admin-radio-main .MuiRadio-root.Mui-checked {
  color: var(--primary);
}

.auth-main {
  display: flex;
  width: 100%;
  height: 100vh;
}

.auth-left-main {
  max-width: 70%;
  width: 100%;
  flex: 0 0 70%;
}

.auth-right-main {
  flex: 0 0 30%;
  width: 100%;
  max-width: 30%;
}

.auth-img-wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.auth-card-main {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card-main .auth-heading {
  font-size: 18px;
  line-height: 28px;
  color: var(--primary);
  font-family: "Poppins-Medium";
  margin-bottom: 30px;
}

.auth-btn-main .auth-btn {
  width: 100%;
  margin-top: 20px;
}

.auth-card-inner {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 25px;
  max-width: 400px;
  width: 100%;
}

.forget-text-main .forget-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary);
  font-family: "Poppins-Regular";
  text-decoration: none;
}

.forget-text-main {
  margin: 0px 0px 10px;
  text-align: right;
}

.auth-card-inner .auth-para {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary);
  font-family: "Poppins-Regular";
  margin-bottom: 30px;
}

.back-btn-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.back-btn-flex .auth-heading {
  margin-bottom: 0;
}

.back-btn svg {
  height: 17px;
  width: 22px;
  object-fit: contain;
  color: var(--white);
}

.back-btn-flex .back-btn {
  background-color: var(--primary);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-timing-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--primary);
  font-family: "Poppins-Regular";
  text-align: center;
  margin: 30px 0px 0px 0px;
}

.otp-timing {
  color: var(--selected-color);
  font-family: "Poppins-Medium";
}

.dashboard-grid-row {
  margin-bottom: 15px;
}

.admin-dashboard-box {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid var(--border);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--white);
}

.admin-dashboard-box .admin-dash-text {
  color: var(--secondary);
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins-Medium';
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.admin-dashboard-box .admin-dash-price {
  color: var(--primary);
  font-size: 15px;
  line-height: 24px;
  font-family: 'Poppins-Medium';
  margin: 5px 0px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.admin-dash-icons {
  height: 25px;
  max-width: 25px;
  object-fit: contain;
}

.admin-dash-icon-box {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  background-color: #1c252e1a;
}

.admin-dash-btn-box {
  display: flex;
  justify-content: flex-end;
}

.admin-dash-btn-box .admin-dash-btn,
.admin-dash-btn-box .admin-dash-btn:hover {
  text-transform: capitalize;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  color: var(--primary);
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  margin: 5px 0px 0px 0px;
}

.admin-dash-icon-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.next-arrow {
  height: 15px;
  width: 20px;
  object-fit: contain;
}

.next-arrow-span {
  display: flex;
}

.card-title-flex .card-title-text {
  font-size: 14px;
  line-height: 20px;
  color: var(--primary);
  font-family: "Poppins-Medium";
}

.card-title-flex {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* start loader css  */

.loading-main {
  background-color: var(--primary);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dots-loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots-loader-text {
  color: var(--white);
  font-size: 20px;
  line-height: 28px;
  font-family: 'Poppins-Medium';
  margin: 50px 0px 0px 0px;
}

.dot-loader {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--white);
  position: relative;
  -webkit-animation: 1.2s grow ease-in-out infinite;
  animation: 1.2s grow ease-in-out infinite;
}

.dot-loader--2 {
  -webkit-animation: 1.2s grow ease-in-out infinite 0.15555s;
  animation: 1.2s grow ease-in-out infinite 0.15555s;
  margin: 0 20px;
}

.dot-loader--3 {
  -webkit-animation: 1.2s grow ease-in-out infinite 0.3s;
  animation: 1.2s grow ease-in-out infinite 0.3s;
}

@-webkit-keyframes grow {

  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes grow {

  0%,
  40%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* end loader css  */
body .MuiMenuItem-root {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins-Regular';
}

body .MuiList-root {
  padding: 0;
}

body .MuiMenu-paper {
  box-shadow: 0 8px 32px #00000014;
  border: 1px solid var(--border);
}

body .MuiAutocomplete-paper {
  box-shadow: 0 8px 32px #00000014;
  border: 1px solid var(--border);
}

body .MuiAutocomplete-paper .MuiAutocomplete-listbox {
  padding: 10px;
}

body .MuiAutocomplete-paper .MuiAutocomplete-listbox li {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins-Regular';
  padding: 5px 0px;
}

body .MuiTooltip-tooltip {
  box-shadow: 0 5px 20px rgba(173, 181, 217, .03);
  background-color: #dce2e8;
  border-radius: 5px;
  color: var(--main-white);
  padding: 4px 8px;
  font-size: 13px;
  line-height: 25px;
  font-family: "Poppins-Medium";
}

body .MuiTooltip-tooltip .MuiTooltip-arrow::before {
  color: #dce2e8;
}

.activity-log-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--dark-border);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.activity-log-flex:last-child {
  margin-bottom: 0;
}
.activity-details{
  width: 100%;
}

.activity-log-inner-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.activity-user-img svg {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.activity-log-flex:last-child {
  margin-bottom: 0;
}

.activity-details .activity-user-name {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary);
  font-family: 'Poppins-Medium';
}

.activity-details .activity-time {
  font-size: 12px;
  line-height: 18px;
  color: var(--selected-color);
  font-family: 'Poppins-Medium';
}

.activity-details .activity-message {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary);
  font-family: 'Poppins-Regular';
}

.activity-user-main .activity-user-img {
  height: 30px;
  width: 30px;
}
.view-page-main{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 10px;
}
.view-indent-card {
  margin: 15px 0px;
}
.view-indent-card:last-child{
  margin-bottom: 0;
}

.indent-activity-log-card {
  margin: 15px 0px 0px;
}

.add-bill-row {
  margin: 15px 0px;
}

.add-bill-card {
  margin: 15px 0px;
}

.add-more-btn-main .add-more-btn,
.add-more-btn-main .add-more-btn:hover {
  background-color: var(--primary);
  padding: 5px;
  min-width: 35px;
  height: 35px;
}

.add-icon {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.add-more-btn-main {
  display: flex;
  gap: 10px;
  align-items: center;
}

.view-pogenrate-card {
  margin: 20px 0px;
}

.admin-nav-main {
  height: calc(100vh - 105px);
  overflow-y: auto;
  padding: 0px 10px;
}

.product-qty-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.indent-inward-card-main {
  margin: 20px 0px;
}

.admin-table-data-btn-flex .admin-table-text-btn,
.admin-table-data-btn-flex .admin-table-text-btn:hover {
  background-color: transparent;
  border-radius: 6px;
  color: var(--primary);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  padding: 6px 10px;
  text-transform: capitalize;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border: 1px solid var(--dark-border);
}

.admin-form-group .MuiStack-root {
  padding-top: 0;
  overflow: hidden;
}

.admin-form-date-picker .MuiInputBase-formControl {
  border: 1px solid #2f2f2f1a;
}

.admin-form-date-picker input {
  padding: 10px 15px;
  font-size: 12px;
  line-height: 16px;
}

.admin-form-date-picker fieldset {
  display: none;
}

.mobile-menu-icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.admin-header-left-main {
  display: flex;
  gap: 10px;
  align-items: center;
}

.admin-header-left-main .hamburger-menu {
  padding: 0;
  min-width: 30px;
}

body .green {
  color: var(--green);
}

body .black {
  color: var(--black);
}

body .red {
  color: var(--red);
}

.bill-list-table-main .table {
  min-width: 1500px;
}


.table-body .bill-table-action-box {
  display: flex;
  gap: 0;
}

.add-input-row {
  margin-bottom: 10px;
}

.required-sign {
  color: var(--red);
  font-size: 12px;
  line-height: 18px;
  font-family: 'Poppins-Medium';
}

.add-user-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.add-user-left-main {
  max-width: 15%;
  width: 100%;
  flex: 0 0 15%;
}

.add-user-right-main {
  flex: 1;
}

.action-td-main{
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-view-table{
  display: flex;
  align-items: center
}

.table-icon-action .table-icon{
  height: 15px;
  width: 15px;
  object-fit: contain;
}