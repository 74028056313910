.activity-log-section {
    margin-bottom: 10px;
}

.activity-log-section .activity-user-profile img {
    border-radius: 50%;
}

.activity-log-section .activity-user-name {
    color: black;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
}

.activity-log-section .activity-time {
    font-size: 11px;
    font-weight: bold;
    color: darkgrey;
}

.main-activit-form {
    height: calc(100vh - 260px);
    overflow: auto;
}

.main-activit-form .activity-message {
    font-size: 13px;
}