

.print-container {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.print-main {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
}

.print-logo {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.print-logo img {
  width: 200px;
  height: 100px;
  padding: 15px 0;
}

.print-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.print-detail-left {
  width: 60%;
}

/* .print-detail-right {
  width: 40%;
} */

.print-detail-left h3,
.print-detail-right h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.print-detail-left p,
.print-detail-right p {
  font-size: 14px;
  margin-bottom: 10px;
}

.print-detail-right table {
  width: 100%;
  border-collapse: collapse;
}

.print-detail-right th,
.print-detail-right td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  color: black;
}

.print-title{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.pritn-data-table {
  margin-bottom: 20px;
}

.pritn-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.pritn-data-table th,
.pritn-data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.pritn-data-table th {
  background-color: #f0f0f0;
}

.pritn-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-direction: column; */
}

.pritn-footer h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pritn-footer h4 {
  font-size: 14px;
  margin-bottom: 10px;
}

.pritn-footer .print-fotter-logo {
  display: flex;
  align-items: center;
}
.pritn-footer .print-fotter-logo img {
  width: 200px;
  height: 100px;
  padding: 15px 0;
}

